export const LIMIT = 9;

export const ERROR_MESSAGE = {
  name: 'Please enter your name',
  companyName: 'Please enter company name',
  designation: 'Please enter your designation',
  employeeNumber: 'Please enter valid Employee Numbers',
  timeZone: 'Please select time zone',
  amount: 'Please enter valid amonut',
  ecosystem: 'Please select ecosytem',
  consultingType: 'Please select consulting type',
  consultingLevel: 'Please select consulting level',
  tier: 'Please select tier',
  stage: 'Please select stage',
  engagement: 'Please select engagement',
  userLocation: 'Please select Location',
  languages: 'Please select primary language',
  skillsInfo: 'Please select atleast one competency',
  industry: 'Please select atleast one industry',
  growthDrivers: 'Please select atleast one growth driver',
  validateEmail: 'Please provide valid email',
  validatePassword: 'Password must contain atleast 8 characters',
  apiError: 'Something went wrong. Please try again',
  parsingError: 'Please fill all the following mandatory fields',
  sameLanguageError: 'Please fill different languages',
  invalidEmails: 'Please provide valid emails for the following',
  error: 'Error',
  noCityFound: 'No city found , please search with valid city name',
  noLanguagesfound: 'No more languages found',
  profileError: 'Something went wrong. Please try again later.',
  companyUrl: 'Please enter a valid url',
  phoneNumber: 'Please enter numeric value of 10 digits',
  typeError: 'File type not allowed',
  sizeError: 'File size must be less than 2 mb',
  emptySheet: 'No data to upload. Please enter some data',
  emailError: 'The email address that you\'ve entered doesn\'t match any account',
  passwordError: 'The password that you\'ve entered is incorrect',
};

export const ROUTE_NAME = {
  mainRoute: '/questionnaire',
  submitted: '/questionnaire/submitted',
};

export const HELPER_TEXT = {
  phoneNumber: 'For eg: (+91) 908777888',
  certification: 'For eg: Adobe Certified Expert (ACE),SEO certification etc',
  network: 'For eg: Mark lucas,Henry John etc',
  experience: 'Enter numeric value',
  employmentHistory: 'Employment History',
  education: 'For eg: Btech,MBA etc',
  sendOtp: 'Please select user',
  sheetUpload: '[Max size : 2 mb, file-type: xls ]',
};

export const LABEL = {
  name: 'Full Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  education: 'Education Qualification',
  certification: 'Certifications',
  networks: 'Professional Networks',
  company1: 'Company one',
  company2: 'Company two',
  company3: 'Company three',
  image: 'Upload Profile Pic',
  companyHeading: 'Company Details',
  companyName: 'Company Name',
  designation: 'Designation',
  companyUrl: 'Company Url',
  professionalSummary: 'Professional Summary',
  sendInvitation: 'Send Invitation',
  emplyeeNumber: 'Number of Employee',
  revenue: 'Revenue',
  amount: 'Revenue',
  ecosystem: 'Ecosystem',
  consultingType: 'Consulting Type',
  topCompanies: 'Top Companies',
  consultingLevel: 'Consulting Level',
  tier: 'Tier',
  stage: 'Stage',
  stages: 'Stages',
  engagement: 'Engagement',
  companySummary: 'Company Summary',
  experience: 'Experience',
  invitation: 'Send Invitation',
  employmentHistory: 'Employment History',
  currency: 'USD',
  profileUploadSuccess: 'Upload status',
  loadingMessage: 'Profile uploading',
  loading: 'Loading',
  primaryLanguage: 'Primary language',
  secondaryLanguauges: 'Secondary languages',
  location: 'Location',
  timeZone: 'Timezone',
  cities: 'Cities',
  filters: 'FILTERS',
  selectAll: 'Select All',
  clearAll: 'Clear All',
  contentLabel: 'Please add a new content',
  titleInput: 'Title',
  coverImageInput: 'Cover Image',
  thumbnail: 'Thumbnail',
  keywordInput: 'Keyword',
  descriptionInput: 'Description',
  overviewInput: 'Overview Summary',
  excelInput: 'Upload Excel',
  videoUrlInput: 'Overview Video',
  attributionInput: 'Attribution',
  activityOverview: 'Activity Overview',
  documentProofLabel: 'Provide document as proof',
  activitySelect: 'Select Activity',
  activityGuide: 'Activity Guide',
};

export const PLACEHOLDER = {
  name: 'Enter full name',
  phoneNumber: 'Number with Country Code',
  education: 'Education Qualification',
  companyName: 'Enter Company Name',
  designation: 'Enter Designation',
  companyUrl: 'Company Url',
  emplyeeNumber: 'Employees',
  amount: 'Please enter revenue',
  experience: 'Experience in years',
  location: 'Search a City *',
  primaryLanguage: 'Select primary langauge *',
  secondaryLanguage: 'Select secondary language',
  timeZone: 'Select Time Zone *',
  search: 'Search user',
};

export const BUTTON = {
  competencies: 'Add Competencies',
  sendOtp: 'Send OTP',
  industry: 'Add Industries',
  create: 'Create',
  update: 'Update',
  cancel: 'Cancel',
  upload: 'Upload Profile Pic',
  user: 'Add User',
  otp: 'Send Otp',
  okay: 'Okay',
  signIn: 'Sign In',
  growthDrivers: 'Add Growth Driver',
  downloadSme: 'Download SME',
  downloadStartup: 'Download STARTUP',
  submit: 'Submit',
  filters: 'Filters',
  clear: 'Clear All',
  selectAll: 'Select All',
  download: 'Download',
  uploadExcel: 'Excel Template',
  survey: 'Survey',
  preview: 'Preview',
  save: 'Save',
  publish: 'Publish',
  createNewQuestionnaire: 'Create New Questionnaire',
  nextQuestion: 'Next Question',
};

export const VERIFICATION_MESSAGE = {
  otpMessage: 'Are you sure you want to send OTP?',
  confirmOtp: 'Confirm OTP request',
  emailHeading: 'Confirm email',
  emailMessageBody:
    'Please note that you will not be able to edit the mail-id in the next screen.'
    + ' Are you sure, the mail id `email` is correct?',
  no: 'No',
  yes: 'Yes',
  ok: 'Okay',
};

export const HEADING = {
  questionnaire: 'Questionnaire',
  successResponse: 'Thank you for submitting the response!',
};
