import React, {
  memo, useState, useEffect,
  useCallback, useRef,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { QuestionnaireRepository } from '../../api/repositories/Questionnaire';
import { UI_CONFIG, VIEW_MODE } from '../../constants/Constants';
import ErrorComponent from '../Common/Error';
import ReviewRespondentForm from '../ReviewRespondentForm';
import ReviewRespondentFormReadonly from '../ReviewRespondentFormReadonly';
import RespondentForm from '../RespondentForm';
import RespondentFormReadonly from '../RespondentFormReadonly';
import { SuccessModal } from '../Common';
import { ROUTE_NAME } from '../../constants/UserConstants';
import { getQueryParams } from '../../utils/HelperFunctions';
import FormView from '../FormView';
import { userConstants } from '../../locale/userTexts';

const QuestionnaireForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [formloader, setFormLoading] = useState(false);
  const [questionnaire, setQuestionnaire] = useState({
    title: '',
    descriptions: '',
  });
  const [questions, setAllQuestions] = useState(null);
  const [responseQuestionMap, setResponseQuestionMap] = useState({});
  const [respondentResponses, setRespondentResponses] = useState(null);
  const [searchParams, setSearchParams] = useState({});
  const [error, setError] = useState('');
  const [showModal, setModalState] = useState(false);
  const params = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const targetRef = useRef();
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const [lang, setLang] = useState('en');

  const onresize = useCallback(() => {
    // note i need to pass the event as an argument to the function
    const { body } = document;
    const html = document.documentElement;
    const width = html.offsetWidth;
    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);
    const htmlframeElement = window.parent;
    setDimension({ height, width });
    htmlframeElement.postMessage({
      type: 'RESIZE',
      data: { width, height },
    }, '*');
  }, [setDimension]);

  const resizeTimer = useCallback(
    () => setTimeout(() => {
      const { body } = document;
      const html = document.documentElement;
      const height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
      if (dimension.height !== height) {
        onresize();
      }
    }, 0.5),
    [dimension.height, onresize],
  );

  useEffect(() => {
    const id = resizeTimer();
    return () => clearInterval(id);
  });

  useEffect(() => {
    window.addEventListener('resize', onresize, false);
    return () => {
      window.removeEventListener('resize', onresize, false);
    };
  }, [onresize]);

  const setQuestionnaireData = async (id, respondentId, view, responseId) => {
    try {
      setLoading(true);
      const questionnaireResp = await QuestionnaireRepository.getQuestionnaireById(id);
      if (questionnaireResp && questionnaireResp.questionnaire) {
        setQuestionnaire({ ...questionnaireResp.questionnaire });
        setLang(questionnaireResp.questionnaire.languageCode);
      }
      const allQuestionsResp = await QuestionnaireRepository.getAllQuestions(id);
      if (allQuestionsResp && allQuestionsResp.questions) {
        const questionIdMap = {};
        allQuestionsResp.questions.forEach((d) => {
          questionIdMap[d.id] = { ...d };
        });
        setResponseQuestionMap(questionIdMap);
        setAllQuestions([...allQuestionsResp.questions]);
      }
      if (view && view === 'readonly') {
        let response;
        if (!responseId) {
          const resp = await QuestionnaireRepository
            .getRespondentResponses(id, respondentId);
          if (resp && resp.responses && Array.isArray(resp.responses) && resp.responses.length) {
            // TODO: showing only last response
            response = resp.responses[0];
          }
        } else {
          const resp = await QuestionnaireRepository
            .getResponseById(responseId);
          if (resp && resp.response) {
            response = resp.response;
          }
        }
        setRespondentResponses({ ...response });
      }
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async function onSearch() {
      if (params && params.id && search) {
        const searchPar = getQueryParams(search);
        const { respondentId, view, responseId } = searchPar;
        setSearchParams(searchPar);
        if (!respondentId && view !== VIEW_MODE.FORM_VIEW) {
          setError('Respondent Id not found');
        } else {
          await setQuestionnaireData(params.id, respondentId, view, responseId);
        }
      } else {
        setError('Respondent Id not found');
      }
    }());
  }, [params, search]);

  const onSubmit = async (responses) => {
    const { id } = params;
    const { respondentId, respondedBy } = searchParams;
    setFormLoading(true);
    const htmlframeElement = window.parent;
    htmlframeElement.postMessage({ type: 'SUBMIT_INIT' }, '*');
    const metadata = JSON.parse(JSON.stringify(searchParams));
    delete metadata.view;
    delete metadata.respondentId;
    delete metadata.hideHeader;
    delete metadata.respondedBy;
    try {
      const payload = {
        response: {
          respondentId,
          responses,
          metadata,
          respondedBy,
        },
      };
      const resp = await QuestionnaireRepository.createResponse(id, payload);
      htmlframeElement.postMessage({ type: 'SUBMIT_SUCCESS', data: { ...resp } }, '*');
      setModalState(true);
    } catch (e) {
      htmlframeElement.postMessage({ type: 'SUBMIT_FAILURE', data: e.message }, '*');
    }
    setFormLoading(false);
  };

  const getRenderComponent = () => {
    const {
      respondentId, view, hideHeader, type,
    } = searchParams;
    let el = null;
    const ifData = questionnaire.title && Array.isArray(questions);
    const showHeader = hideHeader === 'true' || hideHeader === 'false' ? JSON.parse(hideHeader) : false;
    if (ifData) {
      if (view && view === VIEW_MODE.FORM_VIEW) {
        el = (
          <FormView
            isLoading={formloader}
            questionnaire={questionnaire}
            questions={questions}
            showHeader={!showHeader}
            language={lang}
          />
        );
      } else if (respondentId) {
        if (type === VIEW_MODE.FORM_TYPE.REVIEW) {
          if (view && view === VIEW_MODE.READ_ONLY) {
            el = (
              <ReviewRespondentFormReadonly
                questionnaire={questionnaire}
                response={respondentResponses}
                questionIdMap={responseQuestionMap}
                showHeader={!showHeader}
              />
            );
          } else {
            el = (
              <ReviewRespondentForm
                isLoading={formloader}
                questionnaire={questionnaire}
                questions={questions}
                onSubmit={onSubmit}
                showHeader={!showHeader}
                language={lang}
              />
            );
          }
        } else if (view && view === VIEW_MODE.READ_ONLY) {
          el = (
            <RespondentFormReadonly
              questionnaire={questionnaire}
              response={respondentResponses}
              questionIdMap={responseQuestionMap}
              showHeader={!showHeader}
              language={lang}
            />
          );
        } else {
          el = (
            <RespondentForm
              isLoading={formloader}
              questionnaire={questionnaire}
              questions={questions}
              onSubmit={onSubmit}
              showHeader={!showHeader}
              language={lang}
            />
          );
        }
      }
    } else {
      el = <ErrorComponent history={history} />;
    }
    return el;
  };

  const onHandleModalYes = () => {
    setModalState(false);
    const htmlframeElement = window.parent;
    htmlframeElement.postMessage({ type: 'RESPONSE_RECORDED' }, '*');
    window.postMessage({ type: 'RESPONSE_RECORDED' }, '*');
    history.push(`${ROUTE_NAME.submitted}?language=${lang}`);
  };
  return (
    <div ref={targetRef}>
      {loading ? (
        <div className="loaderCont">
          <CircularProgress size={UI_CONFIG.loaderSize} />
        </div>
      )
        : error === '' ? getRenderComponent()
          : <ErrorComponent history={history} />}
      <SuccessModal
        showModal={showModal}
        title={userConstants(lang).successHeader}
        primaryContext={userConstants(lang).submitResponseSuccess}
        handleYes={onHandleModalYes}
        okButtonText={userConstants(lang).okayBtn}
      />
    </div>
  );
};

export default memo(QuestionnaireForm);
