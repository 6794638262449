import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { IApiClientErrorDetails } from '../../network/apiClientError';
import { QuestionnaireForm } from '../../components';
import { ErrorPage } from '../../components/Common';
import {
  logout, createQuestionnaire, getQuestions,
  getAllQuestionnaire, putQuestionnaire, resetQuestionnaire,
} from './actions';
import { userConstants } from '../../locale/userTexts';
import { ROUTE_NAME } from '../../constants/UserConstants';
import './index.scss';

interface IProps {
  history: {
    push: (url: string) => void,
  };
  match: {
    url: string;
  };
  selected: {
    index: number,
  };
  location: {
    pathname: string;
    search: string;
  };
  showProgress: boolean;
  logout: () => void;
  getAllQuestionnaireDispatch: () => void;
  createQuestionnaire: (payload: any) => void;
  error: {
    details: IApiClientErrorDetails;
  };
}

class Questionnaire extends Component<IProps> {
  PARENT_ROUTE = ROUTE_NAME.mainRoute;

  componentDidMount() {
    const { getAllQuestionnaireDispatch } = this.props;
    getAllQuestionnaireDispatch();
  }

  public render() {
    const questionnaireForm = () => (
      <QuestionnaireForm
        {...this.props}
        parent={this.PARENT_ROUTE}
      />
    );
    const successSubmitionScreen = () => {
      const searchQuery = this.props.location.search;
      const searchParams = new URLSearchParams(searchQuery);
      const language = searchParams.get('language');
      return (
        <div className="fullCont">
          <div className="successCont">
            <h4>{userConstants(language).finalSuccessMessage}</h4>
          </div>
        </div>
      );
    };
    return (
      <div>
        <div className="content_body">
          <div
            className={classNames('display-loader', {
              active: this.props.showProgress,
            })}
          >
            <LinearProgress color="secondary" className="loader" />
          </div>
          <Switch>
            <Route
              path={`${this.props.match.url}/submitted`}
              render={() => successSubmitionScreen()}
            />
            <Route
              path={`${this.props.match.url}/:id`}
              component={questionnaireForm}
            />
            <Route
              path="*"
              exact
              component={ErrorPage}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logout()),
  createQuestionnaire: (payload: any) => dispatch(createQuestionnaire(payload)),
  getQuestions: (payload: any) => dispatch(getQuestions(payload)),
  getAllQuestionnaireDispatch: () => dispatch(getAllQuestionnaire()),
  putQuestionnaire: (payload: any) => dispatch(putQuestionnaire(payload)),
  resetQuestionnaire: () => dispatch(resetQuestionnaire()),
});

const mapStateToProps = (state: any) => ({
  error: state.errorHandling.error,
  questionnaire: state.questionnaire,
});
export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
