import React, { memo } from 'react';
import { Rating } from '@material-ui/lab';
import { RATING_CONFIG } from '../../../config/RatingConfig';

const StarRating = (props) => {
  const { ratingConfig } = props;
  const config = { ...RATING_CONFIG, ...ratingConfig };
  const {
    count, emptyIcon, value, filledIcon, edit, size, onChange,
  } = config;
  return (
    <Rating
      value={value}
      precision={1}
      emptyIcon={emptyIcon}
      icon={filledIcon}
      disabled={!edit}
      max={count}
      size={size}
      onChange={(event, newValue) => {
        event.target.value = newValue;
        onChange(event);
      }}
    />
  );
};

export default memo(StarRating);
