import { IApiClient } from '../../network/interfaces';
import { ApiClient } from '../../network/client';
import { IQuestionnaireRepository } from './interfaces';
import * as I from '../models/Questionnaire';

const ENDPOINTS = {
  getQuestionnaireById: (id: string) => `/api/v1/questionnaireservice/questionnaires/${id}`,
  putQuestionnaire: (id: string) => `/api/v1/questionnaireservice/questionnaires/${id}`,
  putQuestion: (id: string) => `/api/v1/questionnaireservice/questions/${id}`,
  getAllQuestions: (id: string) => `/api/v1/questionnaireservice/questions?questionnaireId=${id}&limit=1000`,
  createResponse: (id: string) => `/api/v1/questionnaireservice/questionnaires/${id}/responses`,
  getRespondentResponses: (id: string, respondentId: string) => `/api/v1/questionnaireservice/questionnaires/${id}/${respondentId}/responses`,
  getResponseById: (id: string) => `/api/v1/questionnaireservice/responses/${id}`,
};

class QuestionnaireRepository implements IQuestionnaireRepository {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  public getQuestionnaireById = async (id: string): Promise<I.IQuestionnaireById> => {
    try {
      const response = await this.apiClient.get(ENDPOINTS.getQuestionnaireById(id));
      return response;
    } catch (error) {
      return error as any;
    }
  }

  public putQuestionnaire = async (id: string, payload: I.IQuestionnairePayload):
    Promise<I.IQuestionnaireById> => {
    try {
      const response = await this.apiClient
        .patch(ENDPOINTS.putQuestionnaire(id), payload);
      return response;
    } catch (error) {
      return error as any;
    }
  }

  public putQuestion = async (id: string,
    payload: I.IQuestionPayload): Promise<{ question: I.IQuestionPayload }> => {
    try {
      const response = await this.apiClient
        .put(ENDPOINTS.putQuestion(id), payload);
      return response;
    } catch (error) {
      return error as any;
    }
  }

  public getAllQuestions = async (id: string): Promise<I.IAllQuestions> => {
    try {
      const response = await this.apiClient
        .get(ENDPOINTS.getAllQuestions(id));
      return response;
    } catch (error) {
      return error as any;
    }
  }

  public createResponse = async (id: string, payload: { 'response': I.IQuestionnaireResponsePayload }):
    Promise<I.IQuestionnaireResponseResponse> => {
    try {
      const response = await this.apiClient
        .post(ENDPOINTS.createResponse(id), payload);
      return response;
    } catch (error) {
      throw new Error(error as any);
    }
  }

  public getRespondentResponses = async (id: string, respondentId: string):
    Promise<I.IQuestionnaireResponseResponse> => {
    try {
      const response = await this.apiClient
        .get(ENDPOINTS.getRespondentResponses(id, respondentId));
      return response;
    } catch (error) {
      return error as any;
    }
  }

  public getResponseById = async (id: string): Promise<I.IQuestionnaireResponseResponse> => {
    try {
      const response = await this.apiClient
        .get(ENDPOINTS.getResponseById(id));
      return response;
    } catch (error) {
      throw new Error(error as any);
    }
  }
}

const questionnaireRepository = new QuestionnaireRepository(ApiClient);
export { questionnaireRepository as QuestionnaireRepository };
