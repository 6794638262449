import { AxiosRequestConfig } from 'axios';
import { LocalStorage } from '../utils/LocalStorage';
import {
  ANONYMOUS_ROUTES,
  AUTH_TOKEN_STORAGE_KEY,
} from './constants';

export const authorizationRequestInterceptor = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  if (config.url && !config.url
    .includes(ANONYMOUS_ROUTES[0])
      && !config.url.includes(ANONYMOUS_ROUTES[1])
  ) {
    const token: any = await LocalStorage.get(AUTH_TOKEN_STORAGE_KEY);

    config.headers = {
      ...config.headers,
      Authorization: `Token ${token}`,
    };
  }
  return config;
};

export const expiredAuthorizationTokenResponseInterceptor = async (error: any): Promise<any> => {
  if (error.message === 'Network Error') {
    return Promise.reject({ error: { message: 'Network Error' } });
  }

  if (error.code === 'ECONNABORTED') {
    // timeout
    return Promise.reject({ error: { message: 'Network Error' } });
  }

  return Promise.reject(error);
};
