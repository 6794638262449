import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    wordSpacing: '4px',
    fontFamily: 'Frank Ruhl Libre !important',
    fontSize: '14px',
    border: '1px solid #dadde9',
    '*': {
      fontFamily: 'Frank Ruhl Libre',
    },
  },
}))(Tooltip);

export default function CustomizedTooltips({ Icon, text }) {
  return (
    <HtmlTooltip
      arrow
      title={text}
    >
      <IconButton
        aria-label="question hint"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <Icon />
      </IconButton>
    </HtmlTooltip>
  );
}
