import React, { memo, useState, useEffect } from 'react';
import {
  Grid, CircularProgress, Button, TextareaAutosize,
} from '@material-ui/core';
import StarRating from '../Common/StarRating/index';
import { UI_CONFIG, QUESTION_TYPE } from '../../constants/Constants';
import { userConstants } from '../../locale/userTexts';
import './index.scss';

function RenderQuestionType(props) {
  const {
    type, onChange, response, language,
  } = props;

  const isMobile = window.innerWidth < 600;
  let ratingConfig;

  switch (type) {
    case QUESTION_TYPE.OPEN_ENDED:
      return (
        <Grid className="textArea">
          <TextareaAutosize
            className="descriptionBox"
            rowsMin={6}
            placeholder={userConstants(language).textAreaPlaceholder}
            value={response}
            onChange={onChange}
          />
        </Grid>
      );
    case QUESTION_TYPE.RATING:
      ratingConfig = {
        size: isMobile ? 'small' : 'medium',
        classNames: 'stars',
        edit: true,
        onChange,
      };
      return (
        <Grid container className="starsWrapper">
          <StarRating ratingConfig={ratingConfig} />
          <span className="ratingDisplay">
            {' '}
            {response}
            /5
            {' '}
          </span>
        </Grid>
      );
    default:
      return null;
  }
}

const QuestionSet = (props) => {
  const {
    questionObj, index, onChange, language,
  } = props;
  const {
    questionText, options, response, type,
  } = questionObj;

  const onChangeOption = (event) => {
    const { value } = event.target;
    let newQuestionObj;
    if (type === QUESTION_TYPE.OPEN_ENDED) {
      newQuestionObj = {
        ...questionObj,
        response: value,
      };
    } else if (type === QUESTION_TYPE.RATING) {
      newQuestionObj = {
        ...questionObj,
        response: value,
      };
    }
    onChange(index, { ...newQuestionObj });
  };

  return (
    <>
      <Grid
        container
        className="reviewRespondentForm"
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {/* conditional grids based on question type */}
        <Grid item xs={type === QUESTION_TYPE.OPEN_ENDED ? 12 : 7}>
          <span className="questionIndex">
            {`${index + 1}.`}
          </span>
          <span className="questionTitle">
            {questionText}
          </span>
        </Grid>
        <Grid item className={type === QUESTION_TYPE.OPEN_ENDED ? 'options' : ''}>
          <RenderQuestionType
            type={type}
            options={options}
            response={response}
            onChange={onChangeOption}
            language={language}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ReviewRespondentForm = (props) => {
  const {
    questionnaire,
    onSubmit, isLoading, language,
  } = props;
  const { questions } = props;
  const [questionsData, setAllQuestions] = useState(null);
  const [isValidResponse, setIsValidResponse] = useState(true);

  useEffect(() => {
    if (questionsData) {
      let isValid = true;
      for (let i = 0; i < questionsData.length; i += 1) {
        const d = questionsData[i];
        if (d.isRequired && (d.response <= -1 || d.response === null || d.response === '')) {
          isValid = false;
          break;
        }
      }
      setIsValidResponse(isValid);
    }
  }, [setIsValidResponse, questionsData]);

  useEffect(() => {
    if (questions && Array.isArray(questions)) {
      // remove multiple choice questions from review questionnaire
      const formattedQuestions = questions.filter(
        (question) => question.type !== QUESTION_TYPE.SINGLE_SELECT,
      );
      setAllQuestions([...formattedQuestions.map((d) => ({
        ...d, response: d.type === QUESTION_TYPE.RATING ? 0 : null,
      }))]);
    }
  }, [questionnaire, questions]);

  const getValue = (d) => {
    let value;
    switch (d.type) {
      case QUESTION_TYPE.OPEN_ENDED:
        value = d.response;
        break;
      case QUESTION_TYPE.RATING:
        value = d.options[d.response - 1];
        break;
      default:
        break;
    }
    return value;
  };

  const handleSubmit = async () => {
    const responses = [];
    questionsData.forEach((d) => {
      if (d.response !== -1) {
        responses.push({
          questionId: d.id,
          response: {
            [d.type]: getValue(d),
          },
          tags: d?.tags || undefined,
        });
      }
    });
    await onSubmit(responses);
  };

  const onChange = (index, updatedData) => {
    const newQuestions = [...questionsData];
    newQuestions[index] = updatedData;
    setAllQuestions(newQuestions);
  };

  return (
    isLoading ? (
      <div className="loaderCont">
        <CircularProgress size={UI_CONFIG.loaderSize} />
      </div>
    ) : (
      <div className="formSection">
        <form>
          { Array.isArray(questionsData) && questionsData.map((d, index) => (
            <QuestionSet
              selected
              key={`question-set-${index}`}
              questionObj={d}
              index={index}
              onChange={onChange}
              language={language}
            />
          ))}
          <Button
            type="button"
            variant="outlined"
            className="submitButton"
            disabled={!isValidResponse}
            onClick={handleSubmit}
            style={{ textTransform: 'none' }}
          >
            {userConstants(language).submitButtonInLowerCase}
          </Button>
        </form>
      </div>
    )
  );
};

export default memo(ReviewRespondentForm);
