export function getQueryParams(url: string) {
  const qparams: any = {};
  const parts = (url || '').split('?');
  let qpart;
  let i: any = 0;

  if (parts.length <= 1) {
    return qparams;
  }
  const qparts = parts[1].split('&');
  // eslint-disable-next-line
  for (i in qparts) {
    qpart = qparts[i].split('=');
    qparams[decodeURIComponent(qpart[0])] = decodeURIComponent(qpart[1] || '');
  }

  return qparams;
}
