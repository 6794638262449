import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import FormLayout from '../FormLayout';

const FormHeader = (props) => {
  const { data } = props;
  const { title, description } = data;
  return (
    <FormLayout>
      <Typography
        className="title"
        style={{
          width: '100%',
          fontSize: '32px',
          fontWeight: 500,
          fontFamily: 'Muli',
          wordBreak: 'break-word',
        }}
      >
        {title}
      </Typography>
      <Typography
        className="description"
        style={{
          width: '100%',
          fontFamily: 'Muli',
          wordBreak: 'break-word',
        }}
      >
        {description}
      </Typography>
    </FormLayout>
  );
};

export default memo(FormHeader);
