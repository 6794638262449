import { combineReducers } from 'redux';
import errorHandlingReducer from './errorHandlingReducer';
import questionnaireReducer from '../container/Questionnaire/reducers';

const appReducer = combineReducers({
  errorHandling: errorHandlingReducer,
  questionnaire: questionnaireReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
