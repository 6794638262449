import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './index.scss';

interface IProps {
    showModal: boolean;
    handleYes: () => void;
    primaryContext?: string;
    title: string;
    okButtonText: string;
}

export default function SuccessModal(props: IProps) {
  return (
    <Dialog
      fullScreen
      open={props.showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ textAlign: 'center' }}
    >
      <div id="alert-modal">
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.primaryContext}
          </DialogContentText>
        </DialogContent>
        <Button onClick={props.handleYes} className="button_action">
          {props.okButtonText}
        </Button>
      </div>
    </Dialog>
  );
}

