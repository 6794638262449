import React, { memo } from 'react';
import { Grid, TextareaAutosize } from '@material-ui/core';
import { QUESTION_TYPE } from '../../constants/Constants';
import StarRating from '../Common/StarRating/index';
import './index.scss';

const QuestionSet = (props) => {
  const { questionObj, index } = props;
  const { questionText, type } = questionObj;
  const isMobile = window.innerWidth < 600;
  let rating; let ratingConfig;

  const getAnswerOnQuestionType = (question) => {
    const { response } = question;
    const { OPEN_ENDED, RATING } = QUESTION_TYPE;

    switch (type) {
      case OPEN_ENDED:
        return (
          <Grid className="textArea">
            <TextareaAutosize
              className="descriptionBox"
              rowsMin={6}
              value={response?.openEnded}
            />
          </Grid>
        );
      case RATING:
        rating = Number(response?.rating?.value);
        ratingConfig = {
          size: isMobile ? 'small' : 'medium',
          classNames: 'stars',
          value: rating,
        };
        return (
          <Grid container className="starsWrapper">
            <StarRating ratingConfig={ratingConfig} />
            <span className="ratingDisplay">
              {' '}
              {rating}
              /5
              {' '}
            </span>
          </Grid>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <Grid
        container
        className="reviewRespondentFormReadonly"
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {/* conditional grids based on question type */}
        <Grid item xs={type === QUESTION_TYPE.OPEN_ENDED ? 12 : 7}>
          <span className="questionIndex">
            {`${index + 1}.`}
          </span>
          <span className="questionTitle">
            {questionText}
          </span>
        </Grid>
        <Grid item className={type === QUESTION_TYPE.OPEN_ENDED ? 'options' : ''}>
          {getAnswerOnQuestionType(questionObj)}
        </Grid>
      </Grid>
    </>
  );
};

const ReviewRespondentFormReadonly = (props) => {
  const { response, questionIdMap } = props;

  return (
    <>
      {
              response && Array.isArray(response.responses)
              && response.responses.length !== 0
                ? response.responses.map((d, index) => {
                  const questionObj = {
                    ...questionIdMap[d.questionId],
                    ...d,
                  };
                  return (
                    <QuestionSet
                      selected
                      key={`question-set-${index}`}
                      questionObj={questionObj}
                      index={index}
                    />
                  );
                })
                : <span>No Response Recorded</span>
          }
    </>
  );
};

export default memo(ReviewRespondentFormReadonly);
