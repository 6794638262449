import React, { memo, useState, useEffect } from 'react';
import {
  Grid, Typography, Divider, TextField,
} from '@material-ui/core';
import { FormLayout, FormQuestion, FormHeader } from '../Common';
import { QUESTION_TYPE } from '../../constants/Constants';
import StarRating from '../Common/StarRating/index';
import { userConstants } from '../../locale/userTexts';
import './index.scss';

const QuestionSet = (props) => {
  const { questionObj, index, language } = props;
  const { questionText, description, isRequired } = questionObj;
  const isMobile = window.innerWidth < 600;
  let rating; let ratingConfig;

  const getAnswerOnQuestionType = (question) => {
    const { type, response } = question;
    const { SINGLE_SELECT, OPEN_ENDED, RATING } = QUESTION_TYPE;
    const noResponseText = userConstants(language).noResponse;
    switch (type) {
      case SINGLE_SELECT:
        return response?.singleSelect?.option || noResponseText;
      case OPEN_ENDED:
        return response?.openEnded || noResponseText;
      case RATING:
        rating = Number(response?.rating?.value);
        ratingConfig = {
          size: isMobile ? 'small' : 'medium',
          classNames: 'stars',
          value: rating,
          count: question?.options?.length,
        };
        return (
          <StarRating ratingConfig={ratingConfig} />
        );
      default:
        return '';
    }
  };
  const getAnswerOtherSection = (question) => {
    const { response, commentType } = question;
    let text;
    if (commentType && commentType === 'ANSWER') {
      text = response?.singleSelect?.responseText;
    } else if (commentType && commentType === 'COMMENT') {
      text = response?.comment?.responseText;
    }
    return text;
  };

  const getAnswerText = (question) => {
    const { commentType } = question;
    let text;
    if (commentType === 'ANSWER') {
      text = 'User Other Answer :';
    } else if (commentType === 'COMMENT') {
      text = 'User Comment for this Ques. :';
    }
    return text;
  };

  return (
    <>
      <Grid container spacing={2}>
        <FormQuestion
          index={index}
          questionText={questionText}
          description={description}
          imageUrl={questionObj?.imageUrl}
          isRequired={isRequired}
        />
        <Grid item xs={12} md={12}>
          {!(questionObj?.commentType === 'ANSWER' && questionObj?.response?.singleSelect?.responseText?.length > 0) && (
            <Typography
              className="questIndex"
              style={{
                fontFamily: 'Frank Ruhl Libre',
                fontWeight: 300,
                marginBottom: '8px',
                paddingLeft: '42px',
              }}
            >
              {getAnswerOnQuestionType(questionObj)}
            </Typography>
          )}
          <div className="other-ans-container">
            {(questionObj?.commentType === 'COMMENT' && questionObj?.response?.comment) && (
              <TextField
                style={{
                  width: '95%',
                  paddingBottom: '1rem',
                  marginLeft: '2rem',
                  marginTop: '0.5rem',
                }}
                id="outlined-textarea"
                label="Comment"
                value={getAnswerOtherSection(questionObj)}
                multiline
                variant="outlined"
                name="commentText"
                disabled
              />
            )}

          </div>

          <div className="other-ans-container">
            {(questionObj?.commentType === 'ANSWER' && questionObj?.response?.singleSelect?.responseText?.length > 0) && (
              <TextField
                style={{
                  width: '95%',
                  paddingBottom: '1rem',
                  marginLeft: '2rem',
                  marginTop: '0.5rem',
                }}
                id="outlined-textarea"
                label="Answer"
                value={getAnswerOtherSection(questionObj)}
                multiline
                variant="outlined"
                name="answerText"
                disabled
              />
            )}

          </div>

        </Grid>
      </Grid>
      <Divider
        variant="middle"
        style={{
          marginBottom: '10px',
        }}
      />
    </>
  );
};

const RespondentFormReadonly = (props) => {
  const {
    questionnaire, response,
    questionIdMap, showHeader, language,
  } = props;
  const [questionnaireData, setQuestionnaire] = useState({
    title: '',
    descriptions: '',
  });

  useEffect(() => {
    if (questionnaire) {
      setQuestionnaire({ ...questionnaire });
    }
  }, [questionnaire]);
  const questionIds = Object.keys(questionIdMap);
  return (
    <div className="newQuestCont">
      {showHeader && (
        <div className="formSect">
          <FormHeader data={questionnaireData} />
        </div>
      )}
      <div className="formSect">
        <FormLayout>
          {
            response && Array.isArray(response.responses)
              && response.responses.length !== 0
              ? questionIds?.map((d, index) => {
                const questionObj = {
                  ...questionIdMap[d],
                  ...(response?.responses?.filter((item) => item.questionId === d))[0],
                };
                return (
                  <QuestionSet
                    selected
                    key={`question-set-${index}`}
                    questionObj={questionObj}
                    index={index}
                    language={language}
                  />
                );
              })
              : <span>No Response Recorded</span>
          }
        </FormLayout>
      </div>
    </div>
  );
};

export default memo(RespondentFormReadonly);
