import React, { Suspense } from 'react';
import {
  Route, Switch, withRouter,
} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Questionnaire } from '../container';
import { ErrorPage } from '../components/Common';
import { ROUTE_NAME } from '../constants/UserConstants';

export class Navigation extends React.PureComponent<any, any> {
  public render() {
    const error = (props: any) => <ErrorPage {...props} homepage />;
    return (
      <Suspense fallback={<CircularProgress />}>
        <Switch>
          <Route path={ROUTE_NAME.mainRoute} component={Questionnaire} />
          <Route path="*" exact render={error} />
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(Navigation);
