import React, { memo } from 'react';
import img from '../../../assets/undraw_page_not_found_su7k.svg';
import './index.scss';

export default memo(() => (
  <>
    <div className="errorDiv">
      <img alt="error" src={img} />
    </div>
  </>
));
