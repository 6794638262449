import React, { memo, useEffect, useState } from 'react';
import {
  Grid, Typography, makeStyles,
  Divider, List, ListItem, CircularProgress,
} from '@material-ui/core';
import { FormLayout, FormQuestion, FormHeader } from '../Common';
import { UI_CONFIG, QUESTION_TYPE } from '../../constants/Constants';
import StarRating from '../Common/StarRating/index';
import { userConstants } from '../../locale/userTexts';
import './index.scss';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'var(--section)',
    paddingLeft: '24px',
  },
}));

function OptionList(props) {
  const { options } = props;
  const classes = useStyles();

  return (
    <List
      className={classes.root}
    >
      {Array.isArray(options) && options.map((option, index) => {
        const { option: optionText } = option;
        return (
          <ListItem alignItems="center" key={`question-option-${index}`}>
            <Typography style={{
              paddingRight: '8px',
            }}
            >
              {`${index + 1}.`}
            </Typography>
            <Typography className="optionText">
              {optionText}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
}

const QuestionSet = (props) => {
  const {
    questionObj, index, onChange, language,
  } = props;
  const {
    questionText, options, description, type, isRequired, imageUrl,
  } = questionObj;
  const onQuestionChange = (e) => {
    const { name, value } = e;
    const newQuestionObj = {
      ...questionObj,
      [name]: value,
    };
    onChange(index, { ...newQuestionObj });
  };

  const isMobile = window.innerWidth < 600;
  const ratingConfig = { size: isMobile ? 'small' : 'medium', count: options?.length };
  return (
    <>
      <Grid container spacing={2}>
        <FormQuestion
          index={index}
          questionText={questionText}
          description={description}
          isRequired={isRequired}
          imageUrl={imageUrl}
        />
        <Grid item xs={12} md={12}>
          {type === QUESTION_TYPE.SINGLE_SELECT && (
            <OptionList options={options} onChange={onQuestionChange} />
          )}

          {type === QUESTION_TYPE.OPEN_ENDED && (
            <div className="questionType questionType-">
              {userConstants(language).enterParagraph}
              <hr className="dashedLine" />
            </div>
          )}

          {type === QUESTION_TYPE.RATING && (
            <div className="questionType">
              <StarRating ratingConfig={ratingConfig} />
            </div>
          )}
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        style={{
          marginBottom: '10px',
        }}
      />
    </>
  );
};

const FormView = (props) => {
  const {
    questionnaire, questions, isLoading, showHeader, language,
  } = props;
  const [questionnaireData, setQuestionnaire] = useState({
    title: '',
    descriptions: '',
  });
  const [questionsData, setAllQuestions] = useState(null);

  useEffect(() => {
    if (questionnaire) {
      setQuestionnaire({ ...questionnaire });
    }
    if (questions && Array.isArray(questions)) {
      setAllQuestions([...questions.map((d) => ({
        ...d, response: -1,
      }))]);
    }
  }, [questionnaire, questions]);

  return (
    isLoading ? (
      <div className="loaderCont">
        <CircularProgress size={UI_CONFIG.loaderSize} />
      </div>
    )
      : (
        <div className="newQuestCont">
          {showHeader && (
            <div className="formSect">
              <FormHeader data={questionnaireData} />
            </div>
          )}
          <div className="formSect">
            <FormLayout>
              {
                Array.isArray(questionsData) && questionsData.map((d, index) => (
                  <QuestionSet
                    selected
                    key={`question-set-${index}`}
                    questionObj={d}
                    index={index}
                    language={language}
                  />
                ))
              }
            </FormLayout>
          </div>
        </div>
      )
  );
};

export default memo(FormView);
