export function userConstants(lang) {
  const esStrings = {
    submitButtonInUpperCase: 'ENVIAR',
    submitButtonInLowerCase: 'Enviar',
    successHeader: 'Éxito',
    submitResponseSuccess: 'Envío exitoso',
    okayBtn: 'OK',
    finalSuccessMessage: '¡Gracias por tus respuestas!',
    textAreaPlaceholder: 'Porfavor escriba su respuesta aqui.',
    enterParagraph: 'Ingrese el párrafo',
    noResponse: 'Sin respuesta registrada',
  };
  const enStrings = {
    submitButtonInUpperCase: 'SUBMIT',
    submitButtonInLowerCase: 'Submit',
    successHeader: 'Success',
    submitResponseSuccess: 'Successfully submitted the response',
    okayBtn: 'OKAY',
    finalSuccessMessage: 'Thank you for submitting the response!',
    textAreaPlaceholder: 'Please write your answer here.',
    enterParagraph: 'Enter paragraph',
    noResponse: 'No Response Recorded',
  };
  const ptStrings = {
    submitButtonInUpperCase: 'ENVIAR',
    submitButtonInLowerCase: 'Enviar',
    successHeader: 'Sucesso',
    submitResponseSuccess: 'Resposta enviada com sucesso',
    okayBtn: 'OK',
    finalSuccessMessage: 'Obrigado por enviar a resposta!',
    textAreaPlaceholder: 'Por favor, Escreva sua resposta aqui.',
    enterParagraph: 'Digite o parágrafo',
    noResponse: 'Nenhuma resposta registrada',
  };

  switch (lang) {
    case 'es': return esStrings;
    case 'pt': return ptStrings;
    case 'en':
    default: return enStrings;
  }
}
